<template>
  <div id="searchProduct">
    <div class="top-search">
      <div class="search-box">
        <img src="@images/search.png" alt/>
        <form style="width:100%;" action onsubmit="return false;">
          <input
            type="search"
            ref="input"
            @keyup="searchEnter"
            v-model="searchVal"
            placeholder="搜索商品名称或关键词"
          />
          <img v-if="searchVal.length" @click="backList" class="clear" src="@images/clear.png" alt/>
        </form>
      </div>
      <div class="search-btn" @click="searchBtn(true)">{{searchBtnName}}</div>
    </div>

    <div class="history-box" v-if="isHistoryShow">
      <div class="history-top">
        <span>历史搜索</span>
        <img src="@images/delete.png" @click="deleteHistory" alt/>
      </div>
      <div class="history-info">
        <div
          class="info-item"
          @click="historyClick(item)"
          v-for="(item, index) in historyInfo"
          :key="index"
        >{{item.product_name}}
        </div>
      </div>
    </div>
    <div class="nothing-box" v-if="!isHistoryShow && isNothing">
      <img src="@images/no-goods.png" alt/>
      <p>哎呀，没有找到相关商品换个词试试吧</p>
    </div>
    <div class="goods-container" ref="goodsList" v-if="!isHistoryShow && !isNothing">
      <ul class="list">
        <li
          class="item"
          v-for="(item, index) in categoryList"
          :key="index"
          @click="handleProduct(item)"
        >
          <img v-lazy="item.product_cover" alt/>
          <img class="out" src="@images/sell-out.png" alt v-if="item.is_recycle == 1"/>
          <img class="out" src="@images/edit-fail.png" alt v-if="item.is_examine == 2"/>

          <div>
            <p class="title">{{item.product_name}} {{item.specifications}} {{item.unit}}</p>
            <div class="bottom">
              <span class="price">￥{{item.vip_price}}</span>
              <span class="btn" v-if="item.is_examine == 1">设置</span>
              <span class="status" v-if="item.is_examine == 0">审核中</span>
              <span class="status" v-if="item.is_examine == 2">重新编辑</span>
            </div>
          </div>
        </li>
        <p class="bottom-tips" v-if="empty">别划了，到底儿啦</p>
      </ul>
    </div>

    <van-popup
      class="position-view"
      v-model="isPositionShow"
      position="bottom"
      :style="{ height: '197px' }"
    >
      <div
        class="handle-item"
        @click="$router.push({path: '/commodityOperate', query: {id: productInfo.id, type:'edit'}})"
      >编辑详情
      </div>
      <div class="handle-item" @click="handleSellOut">售罄</div>
      <div class="handle-item active" @click="handleDismount">下架</div>
      <!-- <div class="handle-item active" @click="handleDelete">删除</div> -->
      <div class="handle-item" @click="isPositionShow = false">取消</div>
    </van-popup>
  </div>
</template>

<script>
  import {Dialog, Toast} from "vant";
  import {
    historyDelete,
    historyInfoList,
    historyList,
    historySave,
    productDelete,
    productDismount,
    productSellOut,
  } from "../api/requist";
  import BScroll from "better-scroll";

  export default {
    components: {},
    props: [],
    data() {
      return {
        isPositionShow: false,
        isNothing: false,
        categoryList: [],
        historyInfo: [],
        searchVal: "",
        searchBtnName: "搜索",
        isHistoryShow: true,
        pageCurrent: 1,
        empty: false, //是否到底了
        pageSize: 10,
        goodsListScroller: null,
      };
    },
    created() {
    },
    mounted() {
      this.getList();
      this.$nextTick(() => {
        this.$refs.input.focus();
      });
    },
    watch: {
      searchVal(n, o) {
        if (n === "") {
          this.searchBtnName = "搜索";
        }
      },
    },
    computed: {},
    methods: {
      backList() {
        this.SpageCurrent = 1;
        this.searchBtnName = "搜索";
        this.searchVal = "";
        this.isHistoryShow = true;
        this.empty = false;
        this.categoryList = [];
        this.isNothing = false;
        // this.$nextTick(() => {
        //   this.subMenuScroller = new BScroll(this.$refs.secondMenuContainer, {
        //     scrollY: false,
        //     taps: false,
        //     scrollX: true,
        //     mouseWheel: true,
        //     click: true,
        //     bounceTime: 200,
        //   });
        //   this.goodsListScroller = new BScroll(this.$refs.goodsList, {
        //     scrollY: true,
        //     scrollX: false,
        //     mouseWheel: true,
        //     click: true,
        //     taps: true,
        //     pullUpLoad: {
        //       threshold: 50,
        //     },
        //   });
        //   this.goodsListScroller.on("pullingUp", () => {
        //     this.secondMenu(this.categroy_subset_id);
        //     this.goodsListScroller.finishPullUp(); // 事情做完，需要调用此方法告诉 better-scroll 数据已加载，否则下拉事件只会执行一次
        //   });
        //   this.goodsListScroller.openPullUp();
        //   this.goodsListScroller.refresh();
        //   this.subMenuScroller.refresh();
        // });
      },
      //售罄
      handleSellOut() {
        let that = this;
        if (this.productInfo.product_status == 0) {
          return Toast("该商品已售罄");
        }
        this.isPositionShow = false;
        this.dialog(
          "",
          "售罄前请先确保此商品无未完成订单之后可通过菜单恢复上架",
          "确认售罄",
          "再想一想",
          function () {
            let formData = new FormData();
            formData.append("merch_id", localStorage.getItem("merch_id"));
            formData.append("product_id", that.productInfo.id);
            formData.append("product_status", 0);
            productSellOut(formData).then((res) => {
              if (res.code === 200) {
                that.categoryList.forEach((item) => {
                  if (item.id === that.productInfo.id) {
                    item.product_status = 0;
                  }
                });
                Toast("售罄成功");
              } else {
                that.dialog(
                  "售罄失败",
                  "请先去处理包含此商品的未完成订单",
                  "立即处理",
                  "稍后处理",
                  function () {
                    that.$router.push({
                      path: "/",
                    });
                  }
                );
              }
            });
          }
        );
      },
      historyClick(item) {
        this.searchVal = item.product_name;
        this.searchBtn(true);
      },
      searchEnter(e) {
        if (e.keyCode == 13) {
          this.searchBtn(true, true);
        }
      },
      async getList() {
        let formData = new FormData();
        formData.append("merch_id", localStorage.getItem("merch_id"));
        let res = await historyList(formData);
        if (res.code === 200) {
          this.historyInfo = res.data;
        }
      },

      async searchBtn(flag, two) {
        if (!two) {
          if (this.searchBtnName === "取消" && flag) {
            return (
              (this.isHistoryShow = true),
                (this.searchBtnName = "搜索"),
                (this.searchVal = "")
            );
          }
        }

        if (this.searchVal === "") {
          return Toast("请输入内容");
        }
        this.searchBtnName = "取消";
        this.isHistoryShow = false;
        // 记录
        if (flag) {
          this.empty = false;
          this.categoryList = [];
          this.pageCurrent = 1;
          this.pageSize = 10;
        }
        if (this.empty) return;

        let formData = new FormData();
        formData.append("merch_id", localStorage.getItem("merch_id"));
        formData.append("product_name", this.searchVal);
        let res = await historySave(formData);

        let formDataInfo = new FormData();
        formDataInfo.append("merch_id", localStorage.getItem("merch_id"));
        formDataInfo.append("product_name", this.searchVal);
        formDataInfo.append("pageCurrent", this.pageCurrent);
        formDataInfo.append("pageSize", this.pageSize);

        let infoRes = await historyInfoList(formDataInfo);
        if (infoRes.code === 200) {
          this.categoryList = [...infoRes.data.ProductList, ...this.categoryList];
          if (infoRes.data.ProductList.length >= this.pageSize) {
            this.pageCurrent = ++this.pageCurrent;
          } else {
            this.empty = true;
          }
          if (this.categoryList.length === 0) {
            this.isNothing = true;
          } else {
            this.$nextTick(() => {
              this.goodsListScroller = new BScroll(this.$refs.goodsList, {
                scrollY: true,
                scrollX: false,
                mouseWheel: true,
                click: true,
                taps: true,
                pullUpLoad: {
                  threshold: 50,
                },
              });
              this.goodsListScroller.on("pullingUp", () => {
                this.searchBtn();
                this.goodsListScroller.finishPullUp(); // 事情做完，需要调用此方法告诉 better-scroll 数据已加载，否则下拉事件只会执行一次
              });
            });
            this.isNothing = false;
          }
        }
      },
      async deleteHistory() {
        let formData = new FormData();
        formData.append("merch_id", localStorage.getItem("merch_id"));
        let res = await historyDelete(formData);
        if (res.code === 200) {
          this.historyInfo = [];
          Toast("删除成功");
        }
      },
      //下架
      handleDismount() {
        let that = this;
        this.isPositionShow = false;
        this.dialog(
          "",
          "下架前请先确保此商品无未完成订单之后可通过菜单恢复上架",
          "确认下架",
          "再想一想",
          function () {
            let formData = new FormData();
            formData.append("merch_id", localStorage.getItem("merch_id"));
            formData.append("product_id", that.productInfo.id);
            formData.append("is_recycle", 1);
            productDismount(formData).then((res) => {
              if (res.code === 200) {
                that.categoryList.forEach((item, index) => {
                  if (item.id == that.productInfo.id) {
                    that.categoryList.splice(index, 1);
                  }
                });

                Toast("下架成功");
              } else {
                that.dialog(
                  "下架失败",
                  "请先去处理包含此商品的未完成订单",
                  "稍后处理",
                  "立即处理",
                  function () {
                    that.$router.push({
                      path: "/",
                    });
                  }
                );
              }
            });
          }
        );
      },
      handleProduct(item) {
        this.productInfo = item;
        this.isPositionShow = true;
      },
      dialog(title, message, confirm, cancel, backFn) {
        Dialog.confirm({
          confirmButtonText: confirm,
          cancelButtonText: cancel,
          confirmButtonColor: "#FF6737",
          cancelButtonColor: "#C0C4CC",
          title: title,
          message: message,
        })
          .then(() => {
            backFn();
          })
          .catch(() => {
          });
      },

      handleDelete() {
        let that = this;
        this.isPositionShow = false;
        this.dialog(
          "",
          "删除前请先确保此商品无为完成订单并且删除后将无法恢复",
          "确认删除",
          "再想一想",
          function () {
            let formData = new FormData();
            formData.append("merch_id", localStorage.getItem("merch_id"));
            formData.append("product_id", that.productInfo.id);
            productDelete(formData).then((res) => {
            });
          }
        );
      },
    },
  };
</script>
<style lang="scss">
  #searchProduct {
    .top-search {
      height: 44px;
      width: 100%;
      padding: 0 0 0 16px;
      box-sizing: border-box;
      // border-bottom: 1px solid rgba(240, 242, 245, 1);
      display: flex;
      justify-content: space-between;
      align-items: center;

      .search-btn {
        padding: 0 20px;
        color: rgba(48, 49, 51, 1);
        font-size: 16px;
      }

      .search-box {
        flex: 1;
        // width: 287px;
        height: 32px;
        background: rgba(245, 247, 250, 1);
        border: 0.5px solid #c0c4cc;
        opacity: 1;
        border-radius: 15px;
        display: flex;
        align-items: center;
        padding: 0 12px;
        box-sizing: border-box;
        position: relative;

        img {
          height: 14px;
          width: 14px;
          margin-right: 8px;
        }

        .clear {
          position: absolute;
          right: 0;
          top: 8px;
          height: 16px;
          width: 16px;
        }

        input {
          color: #303133;
          width: 100%;
          height: 20px;
          line-height: 20px;
          font-size: 14px;
          border: none;
          outline: none;
          box-sizing: border-box;
          background: rgba(245, 247, 250, 1);

          &::placeholder {
            font-size: 14px;
            color: rgba(192, 196, 204, 1);
          }
        }
      }
    }

    .history-box {
      min-height: calc(100vh - 44px);
      padding: 12px 16px;
      box-sizing: border-box;

      .history-top {
        display: flex;
        align-items: center;
        justify-content: space-between;

        span {
          font-size: 14px;
          font-weight: bold;
          color: rgba(48, 49, 51, 1);
        }

        img {
          height: 20px;
          width: 20px;
        }
      }

      .history-info {
        margin-top: 8px;
        width: 100%;

        .info-item {
          float: left;
          margin-right: 8px;
          margin-bottom: 8px;
          padding: 0 12px;
          height: 28px;
          line-height: 28px;
          box-sizing: border-box;
          background: rgba(240, 242, 245, 1);
          opacity: 1;
          color: rgba(96, 98, 102, 1);
          border-radius: 14px;
          font-size: 14px;
        }
      }
    }

    .goods-container {
      height: calc(100vh - 44px);
      overflow: hidden;

      .bottom-tips {
        font-size: 12px;
        color: rgba(192, 196, 204, 1);
        text-align: center;
      }

      .list {
        padding: 18px 12px;
        box-sizing: border-box;
      }

      li {
        position: relative;
        display: flex;
        margin-bottom: 24px;

        &:last-child {
          margin-bottom: 0;
        }

        img {
          height: 80px;
          width: 80px;
          margin-right: 8px;
        }

        .out {
          position: absolute;
          top: 0;
          left: 0;
          margin: 0;
        }

        & > div {
          flex: 1;

          .title {
            text-align: left;
            height: 40px;
            line-height: 20px;
            color: rgba(48, 49, 51, 1);
            font-size: 14px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
          }

          .bottom {
            margin-top: 18px;
            display: flex;
            justify-content: space-between;

            .price {
              color: rgba(255, 72, 72, 1);
              font-size: 16px;
            }

            .btn {
              width: 60px;
              height: 22px;
              background: rgba(255, 103, 55, 1);
              box-shadow: 0 2px 4px rgba(255, 103, 55, 0.16);
              border-radius: 13px;
              text-align: center;
              color: rgba(255, 255, 255, 1);
              font-size: 12px;
              line-height: 22px;
            }

            .status {
              font-size: 12px;
              color: #ff6737;
            }
          }
        }
      }
    }

    .nothing-box {
      height: calc(100vh - 44px);
      padding-top: 64px;
      box-sizing: border-box;

      img {
        height: 248px;
        width: 100%;
        display: block;
        margin: 0 auto 12px;
      }

      p {
        margin: 0 auto;
        width: 132px;
        line-height: 18px;
        color: rgba(144, 147, 153, 1);
        font-size: 12px;
      }
    }

    .handle-item {
      height: 46px;
      line-height: 46px;
      color: rgba(48, 49, 51, 1);
      font-size: 14px;
      background: rgba(255, 255, 255, 1);

      &:nth-child(1) {
        border-bottom: 1px solid rgba(242, 246, 252, 1);
      }

      &:nth-child(2) {
        border-bottom: 1px solid rgba(242, 246, 252, 1);
      }

      &.active {
        color: #ff4848;
      }

      &:nth-child(3) {
        border-bottom: 12px solid rgba(242, 246, 252, 1);
      }
    }

    .position-view {
      background: rgba(48, 49, 51, 0.2);
      border-radius: 4px 4px 0 0;
      text-align: center;
    }
  }
</style>
